<template>
  <div class="wenls">
      <div class="lsinfo">
        <div class="base_w">
          <div class="infoleft">
            <img :src="lawyerObj.photo" alt="" class="lsimg">
            <div class="infodet">
              <p class="text1">{{lawyerObj.lawyerName}}律师 </p>
              <div class="text2">
                好评率：<span>{{lawyerObj.haopinhlv}} % </span>  接单率: <span>{{lawyerObj.jiedanums}}次</span>
              </div>
              <div class="text3">  <p> 擅长领域： </p>  <span v-for="(data, index) in lawyerObj.goodsIdsArr" :key="index">{{
              data
            }}</span></div>
            </div>
          </div>
        </div>
      </div>

      <!-- 提问 -->
      <div class="twbox base_w">
        <div class="common_title_box">
          <span class="title">提问</span>
        </div>
        <zixun :type="3"></zixun>
      </div>

     
  </div>
</template>

<script>
import zixun  from '@/components/zixun'

import apiUrl from '@/api/main'

export default {
  components: {zixun},
  data() {
      return {
        lawyerObj:{}
      };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {
    this.getdetail();
    // 跳转回顶部
    document.documentElement.scrollTop = 0;
  },
  methods: {
     // 获取律师详情
     getdetail() {
      let data = {
        lawyerId: this.$route.query.lawid
      }

      apiUrl.lsLawyerinfo(data).then((res) => {
        // 擅长领域
        if (res.data.goodsIds) {
          res.data.goodsIdsArr = res.data.goodsIds.split(',');
        }
        // 提供服务
        res.data.fuwjiage = res.data.fuwjiage ? JSON.parse(res.data.fuwjiage) : [];
      

        this.lawyerObj = res.data;
      })
    },
  },

  }
</script>
<style lang='scss' scoped>
.wenls{


.lsinfo{
    background:url('../../assets/img/bg3.png') no-repeat;
    background-size: 100% 100%;
    height: 280px;
    padding-top: 40px;
    padding-left: 33px;
    .infoleft{
      display: flex;
      align-items: center;
     
      .lsimg{
        width: 136px;
        height: 136px;
        border-radius:50%;
        margin-right: 32px;
      }
      .infodet{
        .text1{
          font-size: 26px;
          font-weight: 600;
          color: #333333;
          margin-right: 13px;
        }
        .text2{
          font-size: 17px;
          color: #333333;
          margin-top: 16px;
          span{
            color: #305BFE;
            margin-right: 16px;
          }
        }
        .text3{
          display: flex;
          flex-wrap: wrap;
          align-items:center;
          color: #333333;
          font-size: 16px;
          margin-top: 25px;
          width: 1000px;
          p{
            margin-bottom: 12px;
          }
          span{
            border-radius: 15px;
            border: 1px solid #CCCCCC;
            padding: 4px 14px;
            margin-left: 12px;
            margin-bottom: 12px;
          }
        }
      }
    }
  
  }

  .twbox{
    height: 541px;
    background-color: #fff;
    box-shadow: 0px 7px 20px 0px rgba(34,42,53,0.08);
    border-radius: 10px;
    padding: 27px 30px;
    // transform: translateY(-57px);
    margin-top: -57px;
    margin-bottom:80px;
    .common_title_box{
      margin-bottom: 19px;
    }
  }



















}
</style>